import {request} from '../../../util/Request'

const CAPACITY_CONFIRMATION_FORM_STATE = {
    price_id: null,
    week_number: null,
    extra_cost_per_vehicle: 0.00,
    total_cost: 0.00,
    currency: 'EUR',
    explanation: null,
    comments: null,
    efl_status: null,
}

export default {
    data() {
        return {
            capacityConfirmationFormVisible: false,
            capacityConfirmationFormState: {...CAPACITY_CONFIRMATION_FORM_STATE},
        }
    },
    methods: {
        async handleCapacityConfirmationSubmit(efl_status) {
            try {
                this.capacityConfirmationFormState.efl_status = efl_status;
                const response = await request({
                    url: 'order/drafts/capacity/confirmations/update',
                    method: 'post',
                    data: {...this.capacityConfirmationFormState},
                })

                this.itemUpdated()
                this.handleCapacityConfirmationClose()
            } catch (error) {
                this.handleServerError(error)
            }
        },
        handleCapacityConfirmationClose() {
            this.capacityConfirmationFormState = {...CAPACITY_CONFIRMATION_FORM_STATE}
            this.capacityConfirmationFormVisible = false
            this.loadList(this.listQueryParams)
        },
        handleCapacityConfirmationClick(item) {
            this.capacityConfirmationFormState.price_id = item.price_id;
            this.capacityConfirmationFormState.week_number = item.week_number;
            this.capacityConfirmationFormState.extra_cost_per_vehicle = item.extra_cost_per_vehicle;
            this.capacityConfirmationFormState.total_cost = item.total_cost;
            this.capacityConfirmationFormState.currency = item.currency;
            this.capacityConfirmationFormState.explanation = _.find(item.history, {status: 4}) ? _.find(item.history, {status: 4}).comments : '';
            this.capacityConfirmationFormVisible = true;
            console.log(this.capacityConfirmationFormState)
        },
    }
}
