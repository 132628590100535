<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">
                                        {{$t('title.capacityConfirmation')}}
                                        <span v-if="pagination.total" >({{pagination.total}})</span>
                                    </template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('orderdraftsview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="capacity-confirmation-table" v-show="!operation">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             :tbody-tr-class="rowClass"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(load_number)="{ item, index, detailsShowing}">
                            <a @click="toggleDetails(item)" class="d-inline in-center">
                                <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                            </a>
                            {{ item.load_number }}
                        </template>
                        <template v-slot:cell(carrier_id)="record">
                            {{record.item.price.supplier ? record.item.price.supplier.name : ''}}
                        </template>
                        <template v-slot:cell(brand)="record">
                            {{  record.item.price.brands.map((item) => item.title).join(', ') }}
                        </template>
                        <template v-slot:cell(route_id)="record">
                            {{record.item.price.route.from_location ? record.item.price.route.from_location.city : ''}} / {{record.item.price.route.to_location ? record.item.price.route.to_location.city : ''}}
                        </template>
                        <template v-slot:cell(requested_loads_number)="record">
                            {{ (record.item.requested_loads_number ? parseFloat(record.item.requested_loads_number).toFixed(1) : '0') }} ({{record.item.requested_loads}})
                        </template>
                        <template v-slot:cell(contractual_loads)="record">
                            {{record.item.contractual_loads}} ({{record.item.contractual_vehicles}})
                        </template>
                        <template v-slot:cell(non_contractual_loads)="record">
                            {{ (record.item.non_contractual_loads ? parseFloat(record.item.non_contractual_loads).toFixed(1) : '0') }} ({{record.item.non_contractual_vehicles}})
                        </template>
                        <template v-slot:cell(accepted_at)="record">
                            <b-badge v-if="record.item.accept_full > 0" :title="$t('title.atnFullyConfirmed')" variant="success">
                                {{$global.utcDateToLocalDate(_.find(record.item.history, {status: 3}).created_at) }}
                            </b-badge>
                            <template v-if="record.item.accept_contractual_difference > 0">
                                <b-badge :title="$t('title.atnFullyAcceptedWithOffer')" variant="warning">
                                    {{$global.utcDateToLocalDate(_.find(record.item.history, {status: 4}).created_at) }}
                                </b-badge>
                                <br>
                                <b-badge v-if="record.item.total_cost > 0 && record.item.efl_status == 'efl_approval_pending'" variant="info">
                                    {{$t('title.eflApprovalPending') }}
                                </b-badge>
                                <b-badge v-if="record.item.total_cost > 0 && record.item.efl_status == 'rejected'" variant="danger" :title="$global.utcDateToLocalDate(_.find(record.item.history, {status: 7}).created_at)">
                                    {{$t('title.rejected') }}
                                </b-badge>
                                <b-badge v-if="record.item.total_cost > 0 && record.item.efl_status == 'approved'" variant="success" :title="$global.utcDateToLocalDate(_.find(record.item.history, {status: 7}).created_at)">
                                    {{$t('title.approved') }}
                                </b-badge>
                            </template>
                            <b-badge v-if="record.item.accept_contractual > 0" :title="$t('title.atnPartiallyAccepted')" variant="info">
                                {{$global.utcDateToLocalDate(_.find(record.item.history, {status: 5}).created_at) }}
                            </b-badge>
                            <b-badge v-if="record.item.cancel > 0" :title="$t('title.atnRejected')" variant="danger">
                                {{$global.utcDateToLocalDate(_.find(record.item.history, {status: 6}).created_at) }}
                            </b-badge>
                        </template>
                        <template v-slot:cell(action)="record">
                            <a @click="handleCapacityConfirmationClick(record.item)"
                               :title="$t('button.title.capacityConfirmation')"
                               v-if="isSupervisor
                                && record.item.total_cost > 0
                                && record.item.efl_status === 'efl_approval_pending'
                                && record.item.accept_contractual_difference > 0" class="ml-1"
                               v-b-tooltip.hover>
                                <i class="fe fe-check-circle"></i>
                            </a>
                            <a @click="setOperation('detail', record.item.id)"
                               :title="$t('button.title.detailItem')" class=" ml-1"
                               v-if="$global.hasPermission('orderdraftsview')"
                               v-b-tooltip.hover>
                                <i class="icmn-info"></i>
                            </a>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left ">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                           size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                v-model="pagination.current"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                :first-text="$t('paginations.first')"
                                :prev-text="$t('paginations.prev')"
                                :next-text="$t('paginations.next')"
                                :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.capacity-confirmation-table-->

                <!-- # Capacity Confirmation Operation -->
                <div class="capacity-confirmation-operation">
                    <a-drawer
                        placement="right"
                        :width="'960px'"
                        :wrapStyle="{overflow: 'auto', paddingBottom: '108px'}"
                        :closable="false"
                        @close="handleCapacityConfirmationClose()"
                        :visible="capacityConfirmationFormVisible"
                        :zIndex="10"
                        :title="$t('title.capacityConfirmation')"
                    >
                            <b-row>
                                <b-col cols="12" v-show="global.pendingRequests > 0">
                                    <a-skeleton active :paragraph="{ rows: 5 }"/>
                                </b-col>
                                <b-col cols="12" v-show="global.pendingRequests <= 0">
                                    <b-row>
                                        <b-col sm="12">
                                            <table class="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th width="200">{{$t('column.extraCostPerVehicle')}}</th>
                                                    <th width="150">{{$t('column.total')}}</th>
                                                    <th width="150">{{$t('column.currency')}}</th>
                                                    <th>{{$t('column.comment')}}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{capacityConfirmationFormState.extra_cost_per_vehicle}}</td>
                                                    <td>{{capacityConfirmationFormState.total_cost}}</td>
                                                    <td>{{capacityConfirmationFormState.currency}}</td>
                                                    <td>{{capacityConfirmationFormState.explanation}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </b-col>
                                        <b-col sm="12">
                                            <b-form-group
                                                :label="$t('input.comment')"
                                                label-for="comments"
                                                :invalid-feedback="formErrors.first('comments')"
                                            >
                                                <b-form-textarea
                                                    v-model="capacityConfirmationFormState.comments"
                                                    :state="((formErrors.has('comments') ? false : null))"
                                                    @focus="$event.target.select()"
                                                ></b-form-textarea>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                    </b-row><!--/b-row-->
                                </b-col><!--/b-col-->
                                <div class="drawer-footer">
                                    <b-row>
                                        <b-col sm="12">
                                            <b-button
                                                size="sm"
                                                type="submit"
                                                variant="primary"
                                                @click="handleCapacityConfirmationSubmit('approved')"
                                                :disabled="global.pendingRequests > 0"
                                                v-b-tooltip.hover :title="$t('button.title.confirm')"
                                            >
                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                             size="12px"
                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                <i class="fa fa-save mr-1"></i>
                                                {{$t('button.confirm')}}
                                            </b-button>
                                            <b-button
                                                size="sm"
                                                class="ml-3"
                                                type="submit"
                                                variant="danger"
                                                @click="handleCapacityConfirmationSubmit('rejected')"
                                                :disabled="global.pendingRequests > 0"
                                                v-b-tooltip.hover :title="$t('button.title.reject')"
                                            >
                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                             size="12px"
                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                <i class="fa fa-save mr-1"></i>
                                                {{$t('button.reject')}}
                                            </b-button>
                                            <b-button variant="warning" class="ml-3"
                                                      size="sm" @click="handleCapacityConfirmationClose()"
                                                      v-b-tooltip.hover :title="$t('button.title.cancel')">
                                                <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-row><!--/b-row-->
                    </a-drawer>
                </div><!--/.capacity-confirmation-operation-->

                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.carriers')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.supplierCarriers"
                                            placeholder=""
                                            v-model="filters.supplierCarriers"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.models')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.models"
                                            placeholder=""
                                            v-model="filters.models"
                                            @input="handleSelectModelsSelect"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.code')">
                                        <treeselect
                                            :multiple="true"
                                            :options=" _.uniqBy(_.filter(dropdowns.configCode, ({ model_id }) => _.every([_.includes(filters.models, model_id)])),'id')"
                                            placeholder=""
                                            v-model="filters.configCode"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.loadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.locations"
                                            placeholder=""
                                            v-model="filters.loadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.unloadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.locations"
                                            placeholder=""
                                            v-model="filters.unloadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.status')">
                                        <treeselect
                                            :multiple="false"
                                            :options="dropdowns.status"
                                            placeholder=""
                                            v-model="filters.status"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.fromAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.toAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>
    </div>
</template>
<script>
import ListingMixin from '../../../util/ListingMixin'
import {mapState} from 'vuex'
import {request} from '@/util/Request'
import Treeselect from '@riophae/vue-treeselect'
import Detail from './detail'
import CapacityConfirmationMixin from "./CapacityConfirmationMixin";
import JsonExcel from "vue-json-excel"
import {getAuthUser} from "@/util/Utils";

const FORM_STATE = {

};

const FILTER_STATE = {
    visible: false,
    from_date: null,
    to_date: null,
    supplierCarriers: [],
    models: [],
    configCode: [],
    loadingPoint: [],
    unloadingPoint: [],
    status: null,
};

const COLUMN_DEFINITION = (self) => [
    {
        label: self.$t('column.supplierCarrier'),
        key: 'carrier_id',
        sortable: true,
    },
    {
        label: self.$t('column.week'),
        key: 'week_number',
        sortable: true,
    },
    {
        label: self.$t('column.brand'),
        key: 'brand',
        sortable: true,
    },
    {
        label: self.$t('column.route'),
        key: 'route_id',
        sortable: true,
    },
    {
        label: self.$t('column.numberOfLoads') + ' (' + self.$t('column.vehicles') + ')',
        key: 'requested_loads_number',
        sortable: true,
    },
    {
        label: self.$t('column.contractual'),
        key: 'contractual_loads',
        sortable: true,
    },
    {
        label: self.$t('column.nonContractual'),
        key: 'non_contractual_loads',
        sortable: true,
    },
    {
        label: self.$t('column.acceptedAt'),
        key: 'accepted_at',
        sortable: true,
    },
    {
        label: self.$t('column.action'),
        class: 'text-right w-100px',
        key: 'action',
    }
];

export default {
    mixins: [ListingMixin, CapacityConfirmationMixin],
    components: {
        Treeselect,
        JsonExcel,
        Detail
    },
    data() {
        return {
            operationTitle: 'title.capacityConfirmation',
            formFields: {...FORM_STATE},
            filters: {...FILTER_STATE},
            listUrl: 'order/drafts/capacity/confirmations',
            columns: COLUMN_DEFINITION(this),
            dropdowns: {
                supplierCarriers: [],
                models: [],
                configCode: [],
                locations: [],
                status: [
                    {id: 1, label: this.$t('title.requestSent')},
                    {id: 2, label: this.$t('title.reminderSent')},
                    {id: 3, label: this.$t('title.atnFullyConfirmed')},
                    {id: 4, label: this.$t('title.atnPartiallyAccepted')},
                    {id: 5, label: this.$t('title.atnFullyAcceptedWithOffer')},
                    {id: 6, label: this.$t('title.atnRejected')},
                    {id: 7, label: this.$t('title.eflApprovalPending')},
                    {id: 8, label: this.$t('title.eflApproved')},
                    {id: 9, label: this.$t('title.eflRejected')},
                ],
            },
            show: true,
            isSupervisor: getAuthUser() ? getAuthUser().is_supervisor : false
        }
    },
    mounted() {
        this.getModels();
        this.getLocations();
        this.getSupplierCarriers();
        if (this.$route.query && this.$route.query.operation === 'edit' && this.$route.query.oToken) {
            this.handleEditClick(this.$route.query.oToken)
        }

        this.$title = this.$t('topBar.navigations.modules.capacityConfirmation')
    },
    methods: {
        toggleDetails(row) {
            this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
        },
        setOperation(operation = 'add', operationToken = null) {
            this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
            this.$router.replace({
                query: Object.assign({},
                    this.$route.query,
                    {
                        ...this.listQueryParams,
                        operation: operation,
                        oToken: operationToken,
                    },
                ),
            }).then(() => {
            }).catch(() => {
            })
        },
        async getSupplierCarriers() {
            try {
                const response = await request({
                    url: '/dropdowns/suppliers/carrier',
                    method: "post",
                })

                const {data} = response
                this.dropdowns.supplierCarriers = data
            } catch (e) {
                this.dropdowns.supplierCarriers = []
            }
        },
        async getModels() {
            try {
                const response = await request({
                    url: '/dropdowns/models',
                    method: "post"
                })

                const {data} = response
                this.dropdowns.models = data.map(item => ({id: item.id, label: `${item.label}`}))
                this.dropdowns.configCode = data.map(item => ({
                    id: item.version_code,
                    label: item.version_code,
                    model_id: item.id
                }))
            } catch (e) {
                this.dropdowns.models = []
                this.dropdowns.configCode = []
            }
        },
        async getLocations() {
            try {
                const response = await request({
                    url: '/dropdowns/locations',
                    method: "post"
                })

                const {data} = response
                this.dropdowns.locations = _.map(data,(i) => {
                    return{
                        id:i.id,
                        label:i.city,
                    }
                })
            } catch (e) {
                this.dropdowns.locations = []
            }
        },
        handleSelectModelsSelect(changeValue) {
            this.filters.configCode = []
        },
        hasListAccess() {
            return this.$global.hasPermission('orderdraftsview')
        },
        getExtraParams() {
            return {
                filters: {
                    ...this.filters,
                    from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                },
            }
        },
        handleResetFilterClick() {
            this.filters = {...FILTER_STATE}
            this.isFilterApplied = 'reset'
            this.handleResetClick()
            this.loadList(this.listQueryParams)
        },
        afterCloseOperation() {
            this.formFields = {...FORM_STATE}
            this.dropdowns.suppliers = []
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.is_cc > 0) return 'bl-2 border-dark'
            if (item.is_spot > 0) return 'bl-2 border-warning'
        },
    },
    computed: {
        ...mapState([
            'global',
            'settings'
        ]),
    },
}
</script>
<style lang="scss">
.w-80 {
    width: 80px;
}

.w-100px {
    width: 100px;
}

.w-220 {
    width: 220px;
}

.in-center {
    position: relative;
    top: 1px;
    margin-right: 8px;
}

@media screen and (max-width: 768px) {
    .ant-drawer-content-wrapper {
        width: 98% !important;
    }
}

.min-height-375 {
    min-height: 375px;
}

:root {
    --ck-z-default: 100;
    --ck-z-modal: calc(var(--ck-z-default) + 999);
}

</style>
