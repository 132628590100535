<template>
    <div v-if="detailLoad">
        <b-row>
            <b-col offset-md="2" md="8" offset-lg="2" lg="8" sm="12">
                <div class="card p-4">
                    <div>
                        <b-row>
                            <b-col lg="10" md="10" sm="12">
                                <h4>
                                    <span class="text-capitalize">{{$t('title.capacityConfirmationDetail')}} </span>
                                </h4>
                                <h6>
                                    <span class="text-secondary">
                                        {{$global.utcDateToLocalDate(detailLoad.created_at) }}
                                    </span>
                                </h6>
                            </b-col>
                            <b-col lg="2" md="2" sm="12" class="text-right">
                                <b-button variant="warning" class="ml-3"
                                          size="sm" @click="handleCancelClick"
                                          v-b-tooltip.hover :title="$t('button.title.back')">
                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.back')}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="mt-3">
                        <div class="box border rounded p-4">
                            <h4><span class="text-capitalize">{{$t('title.capacityConfirmation')}}: </span></h4>
                            <hr>
                            <div class="clearfix">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.weekNumber')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.week_number}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.route')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.price.route.from_location.city}} - {{detailLoad.price.route.to_location.city}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.supplierCarrier')}}</span>
                                        </h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.price.supplier.name}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.model')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            {{detailLoad.model_names}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.requestSentAt')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            {{$global.utcDateToLocalDate(_.find(detailLoad.history, {status: 1}).created_at)}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row v-if="detailLoad.second_reminder_sent > 0">
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.reminderSentAt')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            {{$global.utcDateToLocalDate(_.find(detailLoad.history, {status: 2}).created_at)}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row v-if="detailLoad.accept_full > 0 || detailLoad.accept_contractual > 0 || detailLoad.accept_contractual_difference > 0 || detailLoad.cancel > 0">
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.acceptanceType')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <b-badge v-if="detailLoad.accept_full > 0" variant="success">
                                                {{$t('title.atnFullyConfirmed')}}
                                            </b-badge>
                                            <b-badge v-if="detailLoad.accept_contractual > 0" variant="info">
                                                {{$t('title.atnPartiallyAccepted')}}
                                            </b-badge>
                                            <b-badge v-if="detailLoad.accept_contractual_difference > 0" variant="warning">
                                                {{$t('title.atnFullyAcceptedWithOffer')}}
                                            </b-badge>
                                            <b-badge v-if="detailLoad.cancel > 0" variant="danger">
                                                {{$t('title.atnRejected')}}
                                            </b-badge>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row v-if="detailLoad.total_cost > 0">
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-capitalize">{{$t('title.eflStatus')}}</span></h6></b-col>
                                    <!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <b-badge v-if="detailLoad.efl_status == 'efl_approval_pending'" variant="info">
                                                {{$t('title.eflApprovalPending') }}
                                            </b-badge>
                                            <b-badge v-if="detailLoad.efl_status == 'rejected'" variant="danger">
                                                {{$t('title.rejected') }}
                                            </b-badge>
                                            <b-badge v-if="detailLoad.efl_status == 'approved'" variant="success">
                                                {{$t('title.approved') }}
                                            </b-badge>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                            </div><!--/.clearfix-->
                        </div><!--/.box-->
                    </div>

                    <div class="mt-3" v-if="detailLoad.total_cost > 0">
                        <div class="box border rounded p-4">
                            <h4>
                                <span class="text-capitalize">{{$t('title.extraCost')}}</span>
                            </h4>
                            <hr>
                            <div class="clearfix">
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th width="200">{{$t('column.extraCostPerVehicle')}}</th>
                                        <th width="120">{{$t('column.total')}}</th>
                                        <th width="120">{{$t('column.currency')}}</th>
                                        <th width="550">{{$t('column.comments')}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{{ detailLoad.extra_cost_per_vehicle }}</td>
                                        <td>{{ detailLoad.total_cost }}</td>
                                        <td>{{ detailLoad.currency }}</td>
                                        <td>{{ _.find(detailLoad.history, {status: 4}) ? _.find(detailLoad.history, {status: 4}).comments : '' }}</td>
                                    </tr>
                                    </tbody>
                                </table><!--/.table.table-striped -->
                            </div><!--/.clearfix-->
                        </div><!--/.box-->
                    </div>

                    <div class="mt-3" v-if="detailLoad.history">
                        <div class="box border rounded p-4">
                            <h4>
                                <span class="text-capitalize">{{$t('title.history')}}</span>
                            </h4>
                            <hr>
                            <div class="clearfix">
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th width="120">{{$t('title.status')}}</th>
                                        <th width="550">{{$t('title.comments')}}</th>
                                        <th width="200">{{$t('title.dateTime')}}</th>
                                        <th width="200">{{$t('title.updatedBy')}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-for="(history, index) in detailLoad.history">
                                        <tr>
                                            <td>
                                                <ao-status :status="history.status"></ao-status>
                                            </td>
                                            <td>{{ history.comments }}</td>
                                            <td>{{ $global.utcDateToLocalDate(history.created_at) }}</td>
                                            <td>{{ history.author ? history.author.name : '' }}</td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table><!--/.table.table-striped -->
                            </div><!--/.clearfix-->
                        </div><!--/.box-->
                    </div>
                </div><!--/.card-->
            </b-col><!--/.col-->
        </b-row><!--/.row-->
    </div><!--/div-->
</template>
<script>
import {request} from "../../../util/Request";

export default {
    props: ['id', 'handleCloseOperation'],
    data() {
        return {
            detailLoad: null,
            items: []
        }
    },
    mounted() {
        if (this.$route.query && this.$route.query.operation === 'detail' && this.$route.query.oToken) {
            this.getDetail(this.$route.query.oToken)
        } else {
            this.serverError()
        }
    },
    methods: {
        async getDetail(id) {
            await request({
                url: `/order/drafts/capacity/confirmations/detail/${id}`,
            })
                .then((response) => {
                    const {data} = response
                    this.detailLoad = data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        handleCancelClick() {
            this.handleCloseOperation()
        }
    }
}
</script>
